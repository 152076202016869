<template>
  <div class="card card-questions mb-4">
    <div class="card-header">
      <p>
        {{ aqui }} - <span class="pontos-obtidos">Pontos obtidos {{ questaoProp.notaAlcancada }}</span> | Valor da
        questão {{ questaoProp.notaMaxima }}
      </p>
    </div>

    <div class="card-body mb-3">
      <p class="card-title">
        <label for="peso"
          >Enunciado <i class="far fa-question-circle" style="color: #d2d2d2"
        /></label>
      </p>

      <div class="content mb-2" v-html="questaoProp.enunciado" />
      <div class="form-group form-group-repeater d-flex flex-column">
        <p class="card-title" style="font-weight: bold">
          <span v-if="questaoProp.isCorreta">
            <img :src="require('../../assets/images/success.svg')" />
          </span>
          <span v-if="!questaoProp.isCorreta && isQuestaoObjetiva">
            <img :src="require('../../assets/images/error.svg')" />
          </span>

          <label for="peso"
            >Resposta <i class="far fa-question-circle" style="color: #d2d2d2"
          /></label>
        </p>
        <div v-if="!isQuestaoObjetiva">
          <div class="card-title">
            {{ questaoProp.resposta }}
          </div>
          <div style="margin-top: 20px">
            <label for="peso">
              Nota do Aluno
              <i class="far fa-question-circle" style="color: #d2d2d2" />
            </label>
            <p>
              <input
                type="range"
                @change="sniffNota(this)"
                style="width: 350px"
                min="0"
                step="0.1"
                :max="this.questaoProp.notaMaxima"
                class="form-control"
                v-model="nota"
              />
              {{ notaView }}
            </p>
          </div>
          <div style="margin-top: 20px">
            <label for="peso">
              Anotações (Privado)
              <i class="far fa-question-circle" style="color: #d2d2d2"
            /></label>
            <textarea class="form-control" v-model="anotacao"></textarea>
          </div>
        </div>

        <div v-else>
          <div
            v-for="(alternativa, index) in lsalternativas"
            :key="alternativa.id"
            class="input-group mb-2"
          >
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  v-model="alternativa.is_correta"
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  disabled
                />
              </div>
              <span class="input-group-text">{{
                labelAlternativa[index]
              }}</span>
            </div>

            <div>
              <div class="content resposta" v-html="alternativa.text" />
            </div>
          </div>
          <div style="margin-top: 20px" v-if="isQuestaoObjetiva">
            <p class="card-title" style="font-weight: bold">
              <label for="peso"
                >Selecionada
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
            </p>
            <div>
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    checked
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    disabled
                  />
                </div>
                <span class="input-group-text">{{ questaoProp.resposta }}</span>
              </div>
            </div>
          </div>
        </div>

       <div class="row" v-show="!isQuestaoObjetiva" style="margin-top: 20px">
          <div class="col-sm-12">
            <div class="float-right">
              <button
                type="button"
                class="btn btn-classic"
                @click.prevent="salvar"
              >
                Registrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  components: {},
  props: {
    questaoProp: {
      type: Object,
    },
    isListProva: {
      type: Boolean,
      required: true,
      default: false,
    },
    isListInseridaProva: {
      type: Boolean,
      required: true,
      default: false,
    },
    aqui: null,
  },
  data() {
    return {
      showAlternativas: false,
      alternativas: this.questaoProp.alternativas,
      assuntos: this.questaoProp.assuntos,
      peso: this.questaoProp.questao.peso,
      notaDoAluno: null,
      labelAlternativa: ["a)", "b)", "c)", "d)", "e)", "f)"],
      isQuestaoObjetiva: null,
      lsalternativas: null,
      nota: this.questaoProp.notaAlcancada || "0",
      anotacao: "",
      notaView: 0,
      questao: {
        questao: null,
        nota: null,
        anotacao: null,
      }
    };
  },
  created() {
    this.isQuestaoObjetiva =
      this.questaoProp.questao.tipo == "Objetiva" ? true : false;
    if (this.questaoProp.questao.tipo == "Objetiva") {
      this.lsalternativas = JSON.parse(this.questaoProp.alternativas);
    }
    this.anotacao = this.questaoProp.anotacao;
  },
  methods: {
    sniffNota(nota) {
      if (this.nota > this.questaoProp.notaMaxima) {
        this.nota = "";
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! A Nota do Aluno não pode ser maior que a nota máxima da questão.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        this.notaView = this.nota;
      }
    },
    salvar() {
      if (!this.nota || this.nota == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou a nota  da questão objetiva.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }

      if (!this.anotacao || this.anotacao == "") {
        this.anotacao = "Corrigido"
      }
      this.questao = {
        questao: this.questaoProp.questao.id,
        nota: this.nota,
        anotacao: this.anotacao,
      };

      this.$parent.registraCorrecao(this.questao);

      // this.$api
      //   .post("/provas-corrigir/" + this.questaoProp.prova.id, this.dadoProva)
      //   .then(() => {
      //     this.$root.$emit("Spinner::hide");

      //     Swal.fire({
      //       position: "center",
      //       icon: "success",
      //       title: "Resposta salva com sucesso!",
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //     setTimeout(function () {
      //       // window.location.reload()
      //     }, 1000);
      //   })
      //   .catch((error) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.badge_assunto {
  background-color: #26a54e !important;
  color: #fff !important;
}
.slidecontainer {
  width: 100%;
}

span.pontos-obtidos{
  color: var(--proj-secondary)!important;
  font-weight: 700;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #4caf50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4caf50;
  cursor: pointer;
}
</style>
